
import { Component, Vue, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';

import { Auth, Classes } from '@/store/modules';
import Thumbnail from '@/components/Thumbnail.vue';
import FeaturedThumbnail from '@/components/FeaturedThumbnail.vue';
import Faq from '@/components/Faq.vue';
import { Meta } from '@/misc/decorators';
import { SortOptions } from '@/store/classes';

@Component({
  name: 'Category',
  components: {
    Thumbnail,
    FeaturedThumbnail,
    Faq
  },

})
export default class Home extends Vue {
  classes: any[] = [];
  count = 0;
  error = '';
  sortBy = 'Date';
  category: any = {};
  subcategories: any[] = [];
  subCategoriesLoading = true;
  breadcrumbs: any[] =[];
  sortOptions: SortOptions[] = ['Date', 'Price: Low to High', 'Price: High to Low', 'Avg. Teacher Ratings'];
  searchBy = '';
  pages = 0;
  categories: any[] = [];
  itemsPerPage = 12;
  currentPage = 1;
  loading = false;




  @Meta
  metaInfo(){
    return {
    title: `Explore ${this.category.name} Classes at SkillToon`,
    link: [
      { rel: 'canonical', href: `https://skilltoon.com/categories/${this.category.id}` }
    ],
    meta: [
      { property: 'og:url', content: `https://skilltoon.com/categories/${this.category.id}` },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: `Explore ${this.category.name} Classes at SkillToon` },
      { property: 'og:description', content:`SkillToon brings you the new way of taking ${this.category.id} classes at home! Explore now!` },
      { property: 'og:image', content: 'https://skilltoon.com/categories_hero.png' },
      { name: 'description', content: this.category.id ? `SkillToon brings you the new way of taking ${this.category.id} classes at home! Explore now!` : 'SkillToon brings you the new way of taking classes at home! Explore now!'},
      { name: 'keywords', content: this.category.id ? `${this.category.id} classes online,  ${this.category.id} classes near me,  ${this.category.id} at home` : 'classes online, classes near me, classes at home' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: `https://skilltoon.com/categories/${this.category.id}` },
      { property: 'twitter:title', content: this.category.name ? `Explore ${this.category.name} Classes at SkillToon` : 'Explore Classes at SkillToon'},
      { property: 'twitter:description', content: this.category.id ? `SkillToon brings you the new way of taking ${this.category.id} classes at home! Explore now!` : 'SkillToon brings you the new way of taking classes at home! Explore now!' },
      { property: 'twitter:image', content: 'https://skilltoon.com/categories_hero.png' },
    ]
    }
  }

  @Watch('$route.query')
  async categoryChanged() {
    this.currentPage = 1;
    this.searchBy = '';
    await this.getClasses();
    await this.setBreadcrumbs();
    await this.checkSubcategories();
  }

  get sortedBy() {
    return Classes.sortBy as SortOptions;
  }

  async sortChanged() {
    Classes.updateSearchParams({sortBy: this.sortBy as SortOptions})
    await this.getClasses()
  }

  async setBreadcrumbs () {
    this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Classes', to: '/search' },
    ];

    if(this.category.path.length && this.category.path.length !== 0){
      const data = await this.determineSubcategory()
      this.breadcrumbs.push({
        text: data.name,
        to: `/categories/${this.category.path[0]}`
      })
    }
    this.breadcrumbs.push({
      text: this.category.name,
      to: `/categories/${this.category.id}`,
    });
  }

  get loggedIn() {
    return Auth.loggedIn;
  }

  get user() {
    return Auth.user;
  }

  get featuredClass() {
    return this.classes.find(class_ => class_.featured);
  }

  async checkSubcategories() {
    this.subCategoriesLoading = true;
    if(this.category.path.length === 0) {
      const { data } = await this.$axios.get(`/categories-with-classes`);
      const filtered = data.filter((course: any) => course.path.length > 0 && course.path[0] === this.$route.params.category);
      this.subcategories = clone(filtered);
    }
    this.subCategoriesLoading = false;
  }

  async determineSubcategory () {
    const { data } = await this.$axios.get(`/categories/${this.category.path[0]}`)
    return data;
  }

  async doLogout() {
    return Auth.openLogoutPage();
  }

  async mounted() {
    this.loading = true;
    await this.getClasses();
    await this.setBreadcrumbs();
    await this.checkSubcategories();
    this.loading = false;
  }

  goToClass(id: string) {
    this.$router.push(`/classes/${id}`);
  }


  async getClasses() {
    try {
      const categoryId = this.$route.params.category;
      const response = await this.$axios.get(`/categories/${categoryId}`);

      this.category = {
        id: categoryId,
        name: response.data.name,
        path: response.data.path
      }

      const queryParams = new URLSearchParams();
      queryParams.append('upcoming', 'true');
      if(this.category.id !== 'others') {
        if(this.sortBy === 'Date'){
          queryParams.append('sort', 'date');
          queryParams.append('sortDesc', 'false');
        }
        if(this.sortBy === 'Price: Low to High'){
          queryParams.append('sort', 'fee');
          queryParams.append('sortDesc', 'false');
        }
        if(this.sortBy === 'Price: High to Low'){
          queryParams.append('sort', 'fee');
          queryParams.append('sortDesc', 'true');
        }
        if(this.sortBy === 'Avg. Teacher Ratings'){
          queryParams.append('sort', 'rating');
          queryParams.append('sortDesc', 'true');
        }

        queryParams.append('categoryId', this.category.id);
      }
      queryParams.append('limit', this.itemsPerPage.toString())
      if(this.currentPage > 1) {
        const offset = (this.currentPage - 1)*this.itemsPerPage
        queryParams.append('offset', offset.toString())
      }
      const res = await this.$axios.get(`/classes?${queryParams.toString()}`)
      const { count, items } = res.data
      this.classes = clone(items)
      this.pages = Math.ceil(count / this.itemsPerPage)
    } catch(err: any){
      this.error = err.message
    }
  }

  async pageChanged() {
    await this.getClasses();
  }

}
