
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "Faq",
})

export default class Faq extends Vue {
  sections: any[] = [];
  loading = false;
  showMore: boolean[] = []

  @Watch('$route.query')
  async updateFAQ(){
    this.sections = [];
    await this.getFaqQuestions();
  }

  async mounted() {
    this.loading = true;
    await this.getFaqQuestions();
    this.loading = false;
  }

  async getFaqQuestions() {
    const { data } = await this.$axios.get('/faq');
    data.forEach((element: any) => {
      element.categories.forEach((el: any) => {
        if(el === this.$route.params.category) {
          this.sections.push(element);
          this.showMore.push(false);
        }
      })
    });
  }
}
